<template>

    <div class="container-fluid">
      <div class="row">

        <div class="col-12 text-center">
          <div class="row">
            <img src="../../assets/images/Bilder_Website_Shop.png" class="w-100" />
          </div>
          <div class="clearfix w-100"></div>
          <div>
            <img src="../../assets/images/Bilder_Website_Shop2.png"
                 class="ticketLogo"/>
          </div>
        </div>

        <div class="container pt-5">
          <div class="form-row text-center text-white pb-5">
            <div class="container">
              <div class="form-row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                  <router-link to="/buy-voucher" class="d-block w-100 clearfix cursor p-2 bg-white text-black rounded-lg overflow-hidden">
                    <img src="../../assets/images/Bild_Gutscheine.jpg" />
                    <div class="w-100 pt-2">
                      <b>Gutscheine</b>
                    </div>
                  </router-link>
                </div>
              </div>
        </div>
          </div>
        </div>

        <div class="container pb-5">
          <div class="form-row text-center text-white pb-5">
            <div class="container py-5 text-center text-white" v-if="state==='loading'">
              <div-loader />
            </div>
            <div class="container py-5 text-center" v-else-if="state==='error'">
              <error-div :message="stateMessage" />
            </div>
            <div class="container" v-else>
              <div class="form-row">
                <div v-for="(category,index) in categories" :key="'categories'+index"
                     class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                  <shop-category-card :category="category" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
</template>

<script>
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
import ShopCategoryCard from "@/components/Shop/ShopCategoryCard";
export default {
  name: "ShopCategories",
  components: {ShopCategoryCard, ErrorDiv, DivLoader},
  props : {
    image : String,
    name : String
  },
  created() {
    this.loadCategories();
  },
  methods : {
    loadCategories() {
      this.state = "loading";
      this.stateMessage = "";
      this.$http.get("front/shopCategories").then(response=>{
        if(response.data.error===undefined) {
          this.state = 'error';
          this.stateMessage = this.$t("errors.unableToLoad");
        } else if(response.data.error) {
          this.state = 'error';
          this.stateMessage = response.data.message;
        } else {
          this.state = 'success';
          this.categories = response.data.list;
        }
      }).catch(()=>{
        this.state = 'error';
        this.stateMessage = this.$t("errors.unableToLoad");
      });
    }
  },
  data() {
    return {
      state : "loading",
      stateMessage : "",
      categories : []
    }
  }
}
</script>
<style>
.ticketLogo {
  width: 50%;
  max-width: 300px;
  margin-top: -50px;
  margin-bottom: 50px;
}
</style>
