<template>

  <router-link
      :to="'shop/'+category.link" custom
      v-slot="{ href, route, navigate }">
    <a class="d-block w-100 clearfix cursor p-2 bg-white text-black rounded-lg overflow-hidden"
         :href="href" @click="navigate">
      <b-img-lazy :src="category.imageUrl" />
      <div class="w-100 pt-2">
        <b>{{category.name}}</b>
      </div>
    </a>
  </router-link>

</template>

<script>
export default {
  name: "ShopCategoryCard",
  props : {
    category : Object
  }
}
</script>

<style scoped>

</style>